
/*CKE5*/

.ck {
    @apply transition-colors ease-linear duration-200 !important
}

.ck:not([style*="font-family"]) {
    @apply font-averta !important
}

.ck.ck-content {
    @apply font-times border border-gray/30 text-black text-[15px] !important
}

.ck.ck-balloon-panel.ck-powered-by-balloon {
    @apply hidden !important
}

.ck.ck-content {
    @apply min-h-[500px]
}

.ck h1, .ck h2, .ck h3, .ck h4 {
    @apply text-black font-times font-bold
}

.ck h2 {
    @apply text-[2em]
}

.ck h2 {
    @apply text-[1.5em]
}

.ck h3 {
    @apply text-[1.17em]
}

.ck h4 {
    @apply text-[1em]
}

.ck.ck-content.ck-focused {
    @apply border border-blue !important
}

.ck.ck-toolbar {
    @apply border rounded-t-lg border-b-transparent border-gray/30 bg-blue-100 !important
}

.ck.ck-button:not(.ck-disabled):not(.ck-color-grid__tile):not(.ck-on) {
    @apply hover:bg-blue/30 text-darkblue !important
}

.ck .ck-list {
    @apply max-h-[300px] lg:max-h-[500px] overflow-y-auto
}

.ck-dropdown,.ck-button:not(.ck-list__item .ck-button):not(.ck-toolbar_vertical .ck-button),.ck-dropdown__panel,.ck-list,.ck-balloon-panel,.ck-balloon-rotator__content>*,.ck-dropdown__panel .ck-toolbar {
    @apply rounded-md !important
}

.ck-list__item:first-child .ck-button, .ck-toolbar_vertical .ck-toolbar__items .ck-button:first-child{
    @apply rounded-t-md !important
}

.ck-list__item:last-child .ck-button,.ck-toolbar_vertical .ck-toolbar__items .ck-button:last-child{
    @apply rounded-b-md !important
}

.ck-dropdown__panel,.ck-balloon-panel {
    @apply shadow-xl !important
}

.ck-focused {
    @apply shadow-none !important
}

.ck-editor__main {
    @apply border-red !important
}

.ck-content ul, .ck ol {
    @apply pl-[40px] my-4 !important
}

.ck-content p{
    @apply my-4 !important
}

.ck strong {
    @apply font-bold !important
}

.ck a,.ck .ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label  {
    @apply text-[#1155CC] underline !important
}

.ck ul, .cl ol, .cl p {
    @apply my-1
}
