
.react-datepicker {
    @apply gap-4 flex-wrap grid
}

.date-range-picker {
    @apply flex gap-5 flex-col sm:flex-row justify-center md:justify-between
}

.react-datepicker:not(.react-datepicker--time-only) .react-datepicker__time-container {
    @apply col-start-1 sm:col-start-2 flex flex-col border-t sm:border-l sm:border-t-0 border-gray/30
}

.react-datepicker-time__header {
    @apply flex justify-center items-center p-2 text-gray-900 text-base
}

.react-datepicker__time-list {
    @apply flex flex-col items-center gap-0.5 h-[248px] overflow-auto rounded-lg focus:outline-none focus-visible:ring-2 ring-blue ring-offset-0 overflow-x-hidden
}

.react-datepicker__time-list::-webkit-scrollbar {
    @apply w-[7px] h-[10px]
  }

.react-datepicker__time-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

.react-datepicker__time-list::-webkit-scrollbar-thumb {
    background-color: rgba(204, 213, 225, 1);
    border-radius: 10px;
  }

.react-datepicker__time-list-item {
    @apply px-5 py-1.5 text-gray-700 focus:outline-none focus-visible:text-blue/50 cursor-pointer w-full text-center
}

.react-datepicker__time-list-item--selected {
    @apply text-blue focus-visible:text-blue
}

.react-datepicker__time-list-item--disabled {
    @apply text-gray-100 focus-visible:text-blue
}

.time-only .react-datepicker__input-container {
    input[type="time"]::-webkit-calendar-picker-indicator {
        display: none;
    }
}

.react-datepicker-wrapper:not(.time-only) .react-datepicker__input-container input {
    @apply block w-full text-base md:text-sm bg-white border border-gray/30 rounded shadow-sm
}

.react-datepicker-popper {
    @apply z-40 text-sm bg-white px-3 py-2 border border-gray/30 shadow-around-sm rounded-xl
}

.react-datepicker__portal {
    @apply absolute z-10 w-72 text-sm transform-none bg-white px-3 py-2 top-12 right-0 border border-gray/30 rounded
}

.react-datepicker__month-container {
    @apply flex flex-col
}

.react-datepicker__month {
    @apply flex flex-col
}

.react-datepicker__current-month {
    @apply ml-2.5 text-lg font-semibold text-darkblue
}

.react-datepicker__week {
    @apply flex justify-around
}

.react-datepicker__day-names {
    @apply flex justify-around text-gray-700 font-semibold text-center text-xs
}

.react-datepicker__day-name {
    @apply w-8 h-8 flex items-center justify-center py-1 rounded-full
}

.react-datepicker__navigation {
    @apply absolute top-2
}

.react-datepicker__navigation--previous {
    @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-blue
}

.react-datepicker__navigation--next {
    @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-blue
}

.react-datepicker__day {
    @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded-full cursor-pointer outline-none focus-visible:ring-2 ring-blue ring-offset-1
}

.react-datepicker__day--keyboard-selected{
    @apply bg-blue/50 text-white focus-visible:ring-2 ring-blue ring-offset-1
}

.react-datepicker__day--disabled {
    @apply cursor-not-allowed opacity-40 hover:bg-transparent
}

.react-datepicker__day--outside-month {
    @apply text-gray-500
}

.date-range-picker .react-datepicker__day--in-range {
    @apply bg-blue-50 text-darkblue
}

.date-range-picker .react-datepicker__day--in-selecting-range {
    @apply bg-blue-50 text-darkblue
}


.date-range-picker .react-datepicker__month-container:last-child .react-datepicker__week:first-child>.react-datepicker__day--outside-month {
    @apply bg-transparent pointer-events-none border-transparent
}

.date-range-picker .react-datepicker__day--range-start, .date-range-picker .react-datepicker__day--range-end, .date-range-picker .react-datepicker__day--range-end:hover, .date-range-picker .react-datepicker__day--range-start:hover {
    @apply bg-blue text-white
}

.date-range-picker .react-datepicker__month-container:nth-child(even) .react-datepicker__week:last-child>.react-datepicker__day--outside-month, .date-range-picker .react-datepicker__month-container:nth-child(odd) .react-datepicker__week:first-child>.react-datepicker__day--outside-month {
    @apply invisible
}

.date-range-picker .react-datepicker__month-container:nth-child(odd) .react-datepicker__week:first-child>.react-datepicker__day--keyboard-selected[tabindex='1'], .date-range-picker .react-datepicker__month-container:nth-child(even) .react-datepicker__week:last-child>.react-datepicker__day--keyboard-selected[tabindex='1'] {
    @apply ring-2 ring-blue ring-offset-1
}

.react-datepicker__day--in-range {
    @apply bg-blue
}

.react-datepicker__day--in-selecting-range {
    @apply bg-blue
}

.react-datepicker__day--selecting-range-start {
    @apply bg-white border-2 border-blue
}

.react-datepicker__day--selecting-range-end {
    @apply bg-white border-2 border-blue
}

.react-datepicker__day--selected {
    @apply bg-blue text-white
}

.react-datepicker__day--range-start {
    @apply bg-darkblue text-white hover:text-darkblue hover:bg-white
}

.react-datepicker__day--range-end {
    @apply bg-darkblue text-white hover:text-darkblue hover:bg-white
}

.react-datepicker-time__caption {
    @apply text-gray-900 flex justify-center font-medium text-base
}

.react-datepicker-time__input {
    @apply flex justify-center text-gray-700
}

input.react-datepicker-time__input {
    @apply focus:outline-none border border-transparent focus:border-blue rounded-lg p-1
}