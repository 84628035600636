@import "@systemeio/ui-shared/preflight.css";
@import "date-time-picker.css";
@import "ckeditor.css";
@import "react-international-phone.css";
@import "cropper.css";
@import '@systemeio/ui-shared/style.css';
@import '@systemeio/file-manager/index.css';
@import 'tip-tap.css';


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-[22px] font-bold text-darkblue font-inter;
    }
    h2 {
        @apply text-2xl lg:text-3xl font-semibold text-darkblue font-inter;
    }
    h3 {
        @apply text-xl lg:text-2xl font-semibold text-darkblue font-inter;
    }

    b {
        @apply font-bold;
    }

    strong {
        @apply font-bold;
    }

    .primary-link {
        @apply text-blue hover:text-gray focus-visible:text-gray outline-none transition-colors ease-linear duration-200
    }

    .main-transition-colors {
        @apply transition-colors ease-linear duration-200
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .counter-increment {
       counter-increment: counter;
    }
}

@layer components {
    .customScroll::-webkit-scrollbar {
        width: 6px;
        height: 10px;
    }

    .customScroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .customScroll::-webkit-scrollbar-thumb {
        background-color: rgba(204, 213, 225, 1);
        border-radius: 10px;
    }

    .customScrollThin::-webkit-scrollbar {
        width: 3px;
    }

    .sliderScroll {
        scroll-snap-type: both mandatory;
        scroll-behavior: smooth;
    }

    .slideScrollSnapAlignStart {
        scroll-snap-align: start;
    }

    .noScrollbar::-webkit-scrollbar {
        width: 0;
        display: none;
    }

    .funnelCustomScroll::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    .funnelCustomScroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .funnelCustomScroll::-webkit-scrollbar-thumb {
        background-color: rgba(204, 213, 225, 1);
        border-radius: 10px;
    }

    /* -------------------------------hide scrollbar---------------------------------------- */
    /* source: https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll */
    .hide-scrollbar {
        -ms-overflow-style: none;  /* Internet Explorer and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* Hide the scrollbar for Chrome, Safari and Opera */
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* ------------------------------------------------------------------------------------- */
  }