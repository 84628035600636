:root {
    --react-international-phone-height: 36px;
    --react-international-phone-background-color: white;
    --react-international-phone-text-color: #222;
    --react-international-phone-font-size: 13px;
    --react-international-phone-border-radius: 4px;
    --react-international-phone-border-color: gainsboro;

    --disabled-background-color: var(
        --react-international-phone-disabled-background-color,
        whitesmoke
    );
        --disabled-text-color: var(
        --react-international-phone-disabled-text-color,
        #666
    );

    --dropdown-item-font-size: var(
    --react-international-phone-dropdown-item-font-size,
        14px
    );
    --dropdown-item-text-color: var(
        --react-international-phone-dropdown-item-text-color,
        --react-international-phone-text-color
    );
    --dropdown-item-height: var(
        --react-international-phone-dropdown-item-height,
        28px
    );
    --dropdown-item-background-color: var(
        --react-international-phone-dropdown-item-background-color,
        --react-international-phone-background-color
    );
    --dropdown-item-dial-code-color: var(
        --react-international-phone-dropdown-item-dial-code-color,
        gray
    );

    --selected-dropdown-item-text-color: var(
        --react-international-phone-selected-dropdown-item-text-color,
        --react-international-phone-text-color
    );
    --selected-dropdown-item-background-color: var(
        --react-international-phone-selected-dropdown-item-background-color,
        whitesmoke
    );
    --selected-dropdown-item-dial-code-color: var(
        --react-international-phone-selected-dropdown-item-dial-code-color,
        --dropdown-item-dial-code-color
    );

    --focused-dropdown-item-background-color: var(
        --react-international-phone-selected-dropdown-item-background-color,
        --selected-dropdown-item-background-color
    );

    --dropdown-shadow: var(
        --react-international-phone-dropdown-shadow,
        2px 2px 16px rgb(0 0 0 / 25%)
    );

    --dropdown-left: var(--react-international-phone-dropdown-left, 0);
    --dropdown-top: var(--react-international-phone-dropdown-left, 44px);

    --country-selector-arrow-size: var(
        --react-international-phone-country-selector-arrow-size,
    4px
    );
    --country-selector-arrow-color: var(
        --react-international-phone-country-selector-arrow-color,
        #777
    );
    --disabled-country-selector-arrow-color: var(
        --react-international-phone-disabled-country-selector-arrow-color,
    #999
    );

    --country-selector-border-color: var(
        --react-international-phone-country-selector-border-color,
        --react-international-phone-border-color
    );
    --country-selector-background-color: var(
        --react-international-phone-country-selector-background-color,
        --react-international-phone-background-color
    );
    --country-selector-background-color-hover: var(
        --react-international-phone-country-selector-background-color-hover,
        whitesmoke
    );
    --disabled-country-selector-background-color: var(
        --react-international-phone-disabled-country-selector-background-color,
        --disabled-background-color
    );


    --dial-code-preview-background-color: var(
        --react-international-phone-dial-code-preview-background-color,
        --react-international-phone-background-color
    );
    --dial-code-preview-border-color: var(
        --react-international-phone-dial-code-preview-border-color,
        --react-international-phone-border-color
    );
    --dial-code-preview-text-color: var(
        --react-international-phone-dial-code-preview-text-color,
        --react-international-phone-text-color
    );
    --dial-code-preview-font-size: var(
        --react-international-phone-dial-code-preview-font-size,
        --react-international-phone-font-size
    );
    --dial-code-preview-disabled-background-color: var(
        --react-international-phone-dial-code-preview-disabled-background-color,
        --disabled-background-color
    );
    --dial-code-preview-disabled-text-color: var(
        --react-international-phone-dial-code-preview-disabled-text-color,
        --disabled-text-color
    );
}